<template>
  <div id="spas-installation">
    <Jumbotron
      header="Spas - Installation"
      lead="Get ready to relax. You deserve it."
      bgImagePath="/media/static/water-wallpaper.jpg"
    >
    </Jumbotron>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";

export default {
  name: "Installation",

  components: {
    Jumbotron
  },

  data() {
    return {};
  },

  methods: {}
};
</script>

<style scoped lang="scss">
</style>
